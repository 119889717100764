import { Box, Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { IconWave } from '../assets/icons/how-it-works/wave';
import { IconSearchAgent } from '../assets/icons/search-agent';
import { CAPTIONS_TEXT_COLOR, LIGHT_GREY_COLOR, LL_BACKGROUND_COLOR } from '../colors';
import { SecretPropertyMarket } from '../components';
import AgencyLogo from '../components/agency-logo';
import { AgentCard } from '../components/agent-card';
import Layout from '../components/layout';
import { Width80Box } from '../components/primitives/Width80Box';
import SearchInput from '../components/search-input';
import Tabs from '../components/tabs';
import { renderContentBlocks } from '.';
import Loading from '../components/loading-component';
import { FlexBox } from '../components/primitives/flex';

export interface FindAgentOrAgencyProps {
  // data: FetchFindAnAgentorAgencyEntries
}


const HorizontalSeparator = () => (
  <hr
    style={{
      opacity: 0.2,
      backgroundColor: ' #bbc9cf',
      marginBottom: '40px',
      marginTop: '40px'
    }}
  />
);

const AgentListing = (props: { agents: any; viewType: 'agent' | 'agency'; theme: Theme }) => {
  const { agents, viewType, theme } = props;
  return (
    <Box>
      <Grid
        container
        // rowSpacing="30px"
        // columnSpacing="20px"
        sx={{
          //   flexFlow: 'row wrap',
          //   justifyContent: 'space-between',

          [theme.breakpoints.down('lg')]: {
            justifyContent: 'center'
          }
        }}
      >
        {agents.map((agent, index) => {
          const { name, designation, profileImg, address, agencyLogo, agencyColor } = agent;
          return (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              display="flex"
              justifyContent="center"
            >
              <AgentCard
                name={name}
                designation={designation}
                profileImg={profileImg}
                viewType={viewType}
                address={address}
                agencyColor={agencyColor}
                agencyLogo={agencyLogo}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const FindAgentOrAgency = (props: FindAgentOrAgencyProps) => {

  const [searchText, setSearchText] = useState<string>('');
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [agents, setAgents] = useState([]);
  const [agencies, setAgencies] = useState([]);

  const [clicked, setClicked] = useState(true);
  const [limit, setLimit] = useState(10);
  const [agencyLimit, setAgencyLimit] = useState(4);




  console.log(agencies, 'search')

  const theme = useTheme();
  const handleChangeSearchText = (value: string) => {
    setSearchText(value);
  };
  const viewType = activeTabIndex === 0 ? 'agent' : 'agency';

  const tabs = [
    {
      label: 'View agents',
      value: 'viewAgents'
    },
    {
      label: 'View agencies',
      value: 'viewAgencies'
    }
  ];

  console.log(viewType,'tabs')

  // const agents = [
  //   {
  //     name: 'Rhett Dallwitz',
  //     designation: 'Co-Founder & CEO',
  //     profileImg: 'https://listingloop.com.au/wp-content/uploads/2020/12/DSC3954-copy.jpg',
  //     address: 'Frankston, Vic 3931',
  //     agencyLogo: 'https://listingloop.com.au/wp-content/uploads/2020/04/First-National.jpg',
  //     agencyColor: '#03457f'
  //   },
  //   {
  //     name: 'Rhett Dallwitz',
  //     designation: 'Co-Founder & CEO',
  //     profileImg: 'https://listingloop.com.au/wp-content/uploads/2020/12/DSC3954-copy.jpg',
  //     address: 'Frankston, Vic 3931',
  //     agencyLogo: 'https://listingloop.com.au/wp-content/uploads/2020/04/Ray-White-rbg25522919.jpg',
  //     agencyColor: '#ffe539'
  //   },
  //   {
  //     name: 'Rhett Dallwitz',
  //     designation: 'Co-Founder & CEO',
  //     profileImg: 'https://listingloop.com.au/wp-content/uploads/2020/12/DSC3954-copy.jpg'
  //   },
  //   {
  //     name: 'Rhett Dallwitz',
  //     designation: 'Co-Founder & CEO',
  //     profileImg: 'https://listingloop.com.au/wp-content/uploads/2020/12/DSC3954-copy.jpg'
  //   },
  //   {
  //     name: 'Rhett Dallwitz',
  //     designation: 'Co-Founder & CEO',
  //     profileImg: 'https://listingloop.com.au/wp-content/uploads/2020/12/DSC3954-copy.jpg'
  //   },
  //   {
  //     name: 'Rhett Dallwitz',
  //     designation: 'Co-Founder & CEO',
  //     profileImg: 'https://listingloop.com.au/wp-content/uploads/2020/12/DSC3954-copy.jpg'
  //   }
  // ];

  // const agencies = [
  //   {
  //     tradingName: 'Ray White Frankston',
  //     address: 'Frankston, Vic 3931',
  //     agents,
  //     logoURL: 'https://listingloop.com.au/wp-content/uploads/2020/04/Ray-White-rbg25522919.jpg',
  //     brand: { primary: '#ffe539' }
  //   },
  //   {
  //     tradingName: 'First national Mornington',
  //     address: '123 Street Name, Mornington, Vic 3931',
  //     agents,
  //     logoURL: 'https://listingloop.com.au/wp-content/uploads/2020/04/First-National.jpg',
  //     brand: { primary: '#03457f' }
  //   }
  // ];


  function queryFetch(query, variables){
  return fetch(`https://graphql-dot-listing-loop-prod.ts.r.appspot.com/graphql`,{
      method:'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        query: query,
      variables: variables
    })
        
      
    }).then(response => response.json()) 
  }


  const handleLimit =()=>{
    setLimit(limit+10)
    setClicked(true)
  }
  
  const handleAgencyLimit =()=>{
    setAgencyLimit(agencyLimit+4)
    setClicked(true)
    console.log('here',agencyLimit)
  }


  React.useEffect(() => {
    // get agents when searched or loaded more

if(clicked && viewType == 'agent'){
  queryFetch(`query getAgents($LIMIT: Int = 10, $agency_contains: String = "", $name_contains: String = "", $address_contains: String = "") {
 
    allAgents(
      LIMIT: $LIMIT
      OR: [{agency_contains: $agency_contains}, {name_contains: $name_contains}, 
        {address_contains: $address_contains}]
    ) {
      Agents {
        _id
        name
        photoURL
        address
        agency
      }
    }
  
}

  `, {'LIMIT': limit, 'agency_contains': searchText, 'name_contains': searchText, 'address_contains':searchText})
    .then(resultData => {
      console.log(resultData,'$$$')
      setAgents(resultData?.data?.allAgents?.Agents)
      setClicked(false)
    }) // set data for the number of stars
   
}

  }, [clicked, limit])



  React.useEffect(() => {
    // get agency when searched or loaded more

if(clicked && viewType == 'agency'){
  queryFetch(`query getAgencies($LIMIT: Int = 4, $address_contains: String = "suite", $tradingName_contains: String = "suite") {
      allAgencies(
        LIMIT: $LIMIT
         OR: [{tradingName_contains: $tradingName_contains}, 
            {address_contains: $address_contains}]
        )
       {
        Agencies {
          _id
          address
          tradingName
          logoURL
          agents(LIMIT: 10) {
            address
            agency
            name
            photoURL
          }
        }
      }
  }
  `, {'LIMIT': agencyLimit, 'address_contains': searchText, 'tradingName_contains': searchText})
    .then(resultData => {
      console.log(resultData,'999')
      setAgencies(resultData?.data?.allAgencies?.Agencies)
      setClicked(false)
    }) // set data for the number of stars
   
}


  }, [clicked, agencyLimit])



  React.useEffect(() => {
    // getch agents when page loads
  queryFetch(`query getAgents($LIMIT: Int = 10, $agency_contains: String = "", $name_contains: String = "", $address_contains: String = "") {
      allAgents(
        LIMIT: $LIMIT
        OR: [{agency_contains: $agency_contains}, {name_contains: $name_contains}, 
          {address_contains: $address_contains}]
      ) {
        Agents {
          _id
          name
          photoURL
          address
          agency
        }
      }
  }
  `, {'LIMIT': limit, 'agency_contains': searchText, 'name_contains': searchText, 'address_contains':searchText})
    .then(resultData => {
      console.log(resultData,'$$$')
      setAgents(resultData?.data?.allAgents?.Agents)
      setClicked(false)
    }) // set data for the number of stars
   


//fetch agency when page loads
  queryFetch(`query getAgencies($LIMIT: Int = 4, $address_contains: String = "suite", $tradingName_contains: String = "suite") {
      allAgencies(
        LIMIT: $LIMIT
         OR: [{tradingName_contains: $tradingName_contains}, 
            {address_contains: $address_contains}]
        )
       {
        Agencies {
          _id
          address
          tradingName
          logoURL
          agents(LIMIT: 10) {
            address
            agency
            name
            photoURL
          }
        }
      }
  }
  `, {'LIMIT': agencyLimit, 'address_contains': searchText, 'tradingName_contains': searchText})
    .then(resultData => {
      console.log(resultData,'999')
      setAgencies(resultData?.data?.allAgencies?.Agencies)
      setClicked(false)
    }) // set data for the number of stars
   


  }, [])

  return (
    <Box>
      <Box
        bgcolor={LL_BACKGROUND_COLOR}
        sx={{
          paddingY: '100px',
          paddingTop: '180px',
          paddingX: '20px',
          [theme.breakpoints.down('md')]: {
            paddingTop: '100px'
          }
        }}
      >
        
        <Width80Box>
          <Grid container flexDirection={{ xs: 'column-reverse', md: 'row' }}>
            <Grid item xs={12} md={7} flexGrow={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <Box width="100%">
                <Typography color="primary.main" variant="h1" fontFamily="Tropiline">
                  {`Find an Agent `}
                </Typography>
                <Typography
                  py={'20px'}
                  sx={{ maxWidth: '530px', [theme.breakpoints.down('md')]: { maxWidth: '100%' } }}
                >
                  {`Discover pre-market and off-market properties you didn’t know were for sale. Listing Loop is where leading agents list their properties first. Get the app for VIP access before everyone else.`}
                </Typography>
                <Box py={'20px'}>
                  <SearchInput
                    placeholder="Search by agent, agency, suburb or postcode"
                    value={searchText}
                    onChange={handleChangeSearchText}
                    setClicked={setClicked}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              flexGrow={1}
              sx={{
                textAlign: 'right',
                [theme.breakpoints.down('md')]: { textAlign: 'center', paddingBottom: '60px' }
              }}
            >
              <IconSearchAgent width="100%" height="auto" style={{ maxWidth: '360px' }} />
            </Grid>
          </Grid>
        </Width80Box>
      </Box>
      <Box style={{ transform: 'rotateX(180deg)' }}>
        <IconWave width="100%" height="auto" marginBottom="-10px" />
      </Box>

      {((agents.length == 0 || agencies.length==0) && !searchText && clicked) ? <><Loading/></> : (
  <Width80Box
        sx={{
          paddingY: '50px',
          paddingTop: '90px',
          [theme.breakpoints.down('md')]: { paddingY: '30px' }
        }}
      > 
        <Typography
          variant="h3"
          color={CAPTIONS_TEXT_COLOR}
          fontFamily="Tropiline"
          textAlign="center"
        >
          Here are some top real estate agents in the loop
        </Typography>
       
        <Box sx={{ paddingTop: '50px', [theme.breakpoints.down('md')]: { paddingY: '30px' } }}>
          <Tabs
            activeTabIndex={activeTabIndex}
            handleActiveTab={(index) => setActiveTabIndex(index)}
            tabs={tabs}
          />
        </Box>
        
        {/* Handle content here when tab change */}
        {/* First For Agent Tabs */}

    
        {viewType === 'agent' ? (
          <>
            <HorizontalSeparator />
            <AgentListing agents={agents} theme={theme} viewType={viewType} />

            {(agents.length == 0  && searchText) ?
       <FlexBox sx={{display:'flex', justifyContent:'center', padding:'20px'}}>
       <Typography>No results found for "{searchText}"</Typography>
     </FlexBox>  :  <Button
        sx={{
          borderRadius: '50px',
          color: 'white',
          textTransform: 'none',
          padding: '15px 35px',
          [theme.breakpoints.down('md')]: {
            padding: '10px 20px'
          }
        }}
        variant="contained"
        onClick={handleLimit}

      >
        Load more agents
      </Button>
      }
           
           
          </>
        ) : (
          <Box>
            {agencies.map((agency, index) => {
              const { tradingName, logoURL, address,  agents } = agency;
             let  brand =  '#03457f' 
              return (
                <>
                  <HorizontalSeparator />
                  <Grid
                    container
                    key={index}
                    spacing="25px"
                    justifyContent={{ xs: 'center', md: 'flex-start' }}
                    sx={{ paddingBottom: '40px', paddingX: '25px' }}
                  >
                    <Grid item>
                      <AgencyLogo
                        containerStyle={{
                          height: '60px',
                          width: '230px',
                          borderRadius: '30px',
                          paddingLeft: '25px',
                          paddingRight: '25px'
                        }}
                        agencyColor={brand}
                        agencyLogo={logoURL}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">{tradingName}</Typography>
                      <Typography color={LIGHT_GREY_COLOR}>{address}</Typography>
                    </Grid>
                  </Grid>
                  <Box>
                    <AgentListing agents={agents} theme={theme} viewType={viewType} />
                   
                  </Box>
                </>
              );
            })}
              {(agencies.length == 0 && searchText) ?
       <FlexBox sx={{display:'flex', justifyContent:'center', padding:'20px'}}>
       <Typography>No results found for "{searchText}"</Typography>
     </FlexBox>  :  <Button
        sx={{
          borderRadius: '50px',
          color: 'white',
          textTransform: 'none',
          padding: '15px 35px',
          [theme.breakpoints.down('md')]: {
            padding: '10px 20px'
          }
        }}
        onClick={handleAgencyLimit}

        variant="contained"
      >
        Load more agencies
      </Button>
      }
            
          </Box>
        )}
        <Box sx={{ paddingTop: '120px',marginTop:'120px', [theme.breakpoints.down('md')]: { paddingTop: '0px' } }}>
          <SecretPropertyMarket />
        </Box>
      </Width80Box>)}
    </Box>
  );
};





FindAgentOrAgency.Layout = Layout;
FindAgentOrAgency.isNavbarBackgroundEmpty = true;


// export const query=(LIMIT = 10) =>{
//   graphql`
//     query FetchFindAnAgentorAgencyEntries($LIMIT: Int=10) {
//       db {
//         allAgents(LIMIT: $LIMIT) {
//           Agents {
//             _id
//             name
//             photoURL
//             address
//             agency
//           }
//         }
//       }
//     }`  }



export default FindAgentOrAgency;

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { LIGHT_GREY_COLOR } from '../colors';
import AgencyLogo from './agency-logo';

export interface AgentShortInfo {
  profileImg: string;
  name: string;
  designation: string;
  viewType: 'agency' | 'agent';
  address?: string;
  agencyLogo?: string;
  agencyColor?: string;
}

const resultTheme = {
  boxSx: {
    height: '254px',
    width: '254px',
    borderRadius: '500px',
    bgcolor: 'white',
    overflow: 'hidden'
  },
  nameSx: {
    fontWeight: 500
  },
  designationSx: {
    color: LIGHT_GREY_COLOR,
    fontFamily: 'Tropiline',
    fontWeight: 500,
    lineHeight: 0
  }
};

export const AgentCard = (props: AgentShortInfo) => {
  const { name, designation, profileImg, address, agencyLogo, viewType, agencyColor } = props;
  return (
    <Box mx="auto" padding="0 20px">
      <Box sx={resultTheme.boxSx} style={{ userSelect: 'none' }}>
        <img
          src={profileImg}
          style={{
            objectFit: 'cover',
            objectPosition: 'top',
            height: '100%',
            maxWidth: resultTheme.boxSx.width,
            width: '100%',
            pointerEvents: 'none'
          }}
        />
      </Box>
      <Box mt="25px" textAlign="center" maxWidth={resultTheme.boxSx.width}>
        <Typography sx={resultTheme.nameSx} variant="h5">
          {name}
        </Typography>
        <Box py={'12px'}>
          <Typography sx={resultTheme.designationSx} component="span" variant={'h6'}>
            {designation}
          </Typography>
          {viewType === 'agent' && (
            <Box>
              <Typography component="span" color={LIGHT_GREY_COLOR} lineHeight="0">
                {address}
              </Typography>
            </Box>
          )}
        </Box>
        {viewType === 'agent' && <AgencyLogo agencyColor={agencyColor} agencyLogo={agencyLogo} />}
      </Box>
    </Box>
  );
};
